@font-face {
    font-family: 'Cubano';
    src: local('Cubano'),
        url(./assets/fonts/cubano-regular-webfont.woff) format('truetype');
}
@font-face {
    font-family: 'Gibson';
    src: local('Gibson'),
        url(./assets/fonts/Gibson-Regular.woff) format('truetype');
}
*::-webkit-scrollbar {
    display: none;
}
* {
    touch-action: none;
    box-sizing: border-box;
}
html {
    background: black;
}
body,
html {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Gibson', Helvetica, Verdana, Geneva, Tahoma, sans-serif;
    -ms-overflow-style: none;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: none;
    scroll-behavior: auto;
    -webkit-overflow-scrolling: auto;
    text-rendering: optimizeLegibility;
    background-color: #444;
    font-size: 16px;
}
