.check-item,
.check-item span {
    position: relative;
    display: flex;
    align-items: center;
}
.check-item .check-item-content {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.check-item .check-box {
    margin: 4px 8px;
}
.check-item .check-mark {
    margin: 4px 4px 4px 12px;
}
