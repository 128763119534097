.select-it {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 175px;
    padding: 16px 0;
}
.select-it * {
    box-sizing: border-box;
}
.select-it > div {
    width: 100%;
    max-width: 90%;
    font-size: 0.8em;
    font-weight: bold;
}
.select-it .sel {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;
    width: 100%;
    /* width: 300px; */
    max-width: 90%;
}
/* !Arrow */
.select-it .sel {
    position: relative;
}
.select-it .sel::after {
    content: '\02C7';
    position: absolute;
    top: 30%;
    right: 8px;
    bottom: 0;
    font-size: 2em;
    color: #999;
    z-index: -1;
    line-height: 0.9em;
}
/* ! */
.select-it .sel select {
    flex: 1;
    position: relative;
    background-color: transparent;
    background: transparent;

    /* color: #444; */
    min-width: 42px;
    padding: 2px 2px 2px 6px;
    margin: 0;
    border-radius: 0px;
    border-color: #999;
    border: 1px solid #999;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    line-height: 1.3em;
    color: #fff;
}
/* .select-it .sel select:hover {
    color: #999;
} */
.select-it .sel select {
    font-size: 0.9em;
}
.select-it .sel select option {
    color: #fff;
}
.select-it .sel:first-of-type select {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.select-it .sel:last-of-type select {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.select-it .sel select:disabled {
    border-color: #bbb;
}

.select-it .sel.required select {
    border-color: red;
}
