.check-box {
    position: relative;
    width: 1em;
    height: 1em;
    /* border-radius: 50%; */
    cursor: pointer;
    transform: scale(0.9);
    transition: transform 0.25s ease;
    transform-origin: 50% 50%;
    background-color: #aaa;
    border-color: red;
    /* box-shadow: inset 0 0 0 3px; */
    /* transition: box-shadow 0.5s 0s ease; */
    transition: background-color 0.5s 0s ease, transform 0.3s 0s ease;
}
.check-box:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    width: 0.7em;
    height: 0.7em;
    margin-top: -0.35em;
    margin-left: -0.35em;
    /* margin: 0 auto; */
    background-color: #333;
    /* transform: translate3d(-50%, -50%, 0) scale(1); */
    transform: scale(1);
    /* border-radius: 50%; */
    transform-origin: 50% 50%;
    /* box-shadow: inset 0 7px 10px #ffbeb8; */
    transition: transform 0.2s 0.1s ease;
}
.check-box::after {
    content: '';
    position: absolute;
    left: 0%;
    bottom: 15%;
    width: 7px;
    height: 12px;
    border-right: 5px solid;
    border-bottom: 5px solid;
    border-color: inherit;
    transform: rotate(0deg) scale(0);
    transform-origin: 100% 100%;
    transition: border-color 1s 1s ease, transform 0.3s 0s ease;
    /* box-shadow: 0 0 8px red; */
}

.check-box:hover {
    transform: scale(1);
}
/* .check-box:active {
    transform: scale(0.95);
} */
.check-box.checked {
    color: #ccc;
    background-color: #ccc;
    transform: scale(0.9);
    /* box-shadow: inset 0 0 0 20px; */
}
.check-box.checked::before {
    /* transform: translate3d(-50%, -50%, 0) scale(0); */
    transform: scale(0);
    transition: transform 0.2s 0s ease;
}
.check-box.checked::after {
    transform: rotate(45deg) scale(1.1);
    transition: transform 0.3s 0.2s ease;
}
